import React from "react";
import "slick-carousel/slick/slick.css";
import clientsData from "../../data/BrandsSlider/clients-brand.json";
import {Col, Row} from "react-bootstrap";

const ClientsBrand = ({children, classAppend}) => {
    const settings = {
        className: "clients-brand-carousel",
        slidesToShow: 6,
        arrows: false,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 501,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };
    return (
        <section className="pt-50 pb-50 white-bg">
            <div className="container">
                {children ?
                    <div className="row w-100 justify-content-center"><Col md={8} lg={8} sm={10}>{children}</Col>
                    </div> : null}
                <div className={"row " + (classAppend ? classAppend : null)}>

                    <Row className="justify-content-center">
                        {
                            clientsData.map((client) => (
                                <Col md={3}>
                                    <div className="client-logo p-4" key={client.id}>
                                        <img
                                            className="img-fluid"
                                            src={client.image}
                                            alt={client.name}
                                        />
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                </div>
            </div>
        </section>
    );
};

export default ClientsBrand;
