import React, {forwardRef} from "react";
import Icofont from "react-icofont";
import Map from "../Maps/Map";
import ContactFormSix from "./ContactFormSix";

const ContactFour = forwardRef((props, ref) => {
    return <>
        <section className="contact-us white-bg" id="contact">
            <div className="container">
                <div className="clearfix">
                    <div className="bg-flex-right col-md-6 map-section">
                        <Map/>
                    </div>
                    <div className="col-about-left col-md-6 text-left">
                        <h2>Get in Touch</h2>
                        <p className="text-uppercase" style={{fontSize: "14px"}}>Kami senang untuk berkomunikasi dan
                            berdiskusi tentang berbagai
                            kebutuhan pajak kendaraan Anda. Hubungi kami melalui salah satu metode dibawah:</p>
                        {/*<button className="btn btn-color btn-circle mt-2" onClick={() => {*/}
                        {/*    window.open(*/}
                        {/*        'mailto:cs@birojasabian.com',*/}
                        {/*        '_blank' // <- This is what makes it open in a new window.*/}
                        {/*    );*/}
                        {/*}}>*/}
                        {/*    Hubungi via Email*/}
                        {/*</button>*/}
                        <ContactFormSix/>
                    </div>
                </div>
            </div>
        </section>
        <section className="p-0" ref={ref}>
            <div className="container-fluid">
                <div className="row row-flex">
                    <div className="col-md-4">
                        <div className="col-inner spacer dark-bg">
                            <div className="text-center white-color">
                                <Icofont icon="google-map" className="font-50px white-icon"/>
                                <h2>Biro Jasa Bian</h2>
                                <p>
                                    Jl. Serayu Timur I No. 2 (Timur Ruko PGM) <br/>
                                    Pandean, Taman, Kota Madiun, Jawa Timur 63133
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="col-inner spacer gradient-overlay-bg">
                            <div className="text-center white-color">
                                <Icofont icon="email" className="font-50px white-icon"/>
                                <h2>Email Us</h2>
                                <p className="mb-0"> cs@birojasabian.com</p>
                                <p className="mb-0"> -</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="col-inner spacer dark-bg">
                            <div className="text-center white-color">
                                <Icofont icon="iphone" className="font-50px white-icon"/>
                                <h2>Whatsapp</h2>
                                <p className="mb-0">(+62) 812 5958 9369</p>
                                <p>(+62) 821 3355 1936</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </>
});

export default ContactFour;
