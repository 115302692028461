import React, {forwardRef} from "react";
import 'swiper/swiper-bundle.css';
import parse from "html-react-parser";
import SliderButtons from "../../elements/SliderButtons/SliderButtons";
import Swiper, {Autoplay, Navigation, Pagination} from "swiper";

import ReactIdSwiperCustom from "react-id-swiper/lib/ReactIdSwiper.custom";

const HeroSliderTwo = forwardRef((props, ref) => {
    const {data} = props

    const params = {
        Swiper,
        loop: true,
        grabCursor: true,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        modules: [Autoplay, Pagination, Navigation]
    };

    return (
        <section className="pt-0 pb-0" ref={ref}>
            <div className="slider-bg flexslider">
                <ul className="slides">
                    <ReactIdSwiperCustom {...params}>
                        {data.map((slide) => (
                            <li key={slide.id}>
                                <div
                                    className="slide-img"
                                    style={{
                                        boxShadow: `inset 0 0 0 999999999px #0053A67A`,
                                        background: `url(${
                                            slide.image}) center center / cover scroll no-repeat`,
                                    }}
                                ></div>
                                <div
                                    className={
                                        "hero-text-wrap " + (slide.bg ? "gradient-overlay-bg" : "")
                                    }
                                >
                                    <div className="hero-text white-color">
                                        <div className="container text-center">
                                            {
                                                slide.title.split("\n").map((v, i) => {
                                                    return <h2
                                                        className="white-color text-uppercase font-700 letter-spacing-5">
                                                        {v}
                                                    </h2>
                                                })
                                            }


                                            <h3 className="white-color font-400">
                                                {slide.tagline ? slide.tagline : ""}
                                            </h3>
                                            <h3 className="white-color font-400">
                                                {slide.text ? parse(slide.text) : ""}
                                            </h3>
                                            {slide.buttons.length !== 0 ? (
                                                <p className="text-center mt-30">
                                                    <SliderButtons buttons={slide.buttons}/>
                                                </p>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ReactIdSwiperCustom>
                </ul>
            </div>
        </section>
    );
});

export default HeroSliderTwo;
