import PageHome from "./pages/home/PageHome";
import PageFAQs from "./pages/faq/FAQs";
import PageAboutUs from "./pages/about/AboutUs";
import PageService from "./pages/services/Service";
import PageContactUs from "./pages/contact/ContactUs";

export const Routes = [
    {
        exact: true,
        path: `${process.env.PUBLIC_URL}/`,
        label: "Home",
        component: PageHome
    },
    {
        exact: true,
        path: `${process.env.PUBLIC_URL}/service`,
        label: "Layanan",
        component: PageService
    },
    {
        exact: true,
        path: `${process.env.PUBLIC_URL}/faq`,
        label: "FAQ",
        component: PageFAQs
    },
    {
        exact: true,
        path: `${process.env.PUBLIC_URL}/contact-us`,
        label: "Contact Us",
        component: PageContactUs
    },
    {
        exact: true,
        label: "About Us",
        path: `${process.env.PUBLIC_URL}/about-us`,
        component: PageAboutUs
    }
]

export const HomeNav = [
    {"id": 1, "title": "Home", "link": "home"},
    {"id": 2, "title": "About Us", "link": "about"},
    {"id": 3, "title": "Layanan", "link": "service"},
    {"id": 4, "title": "Contact Us", "link": "contact"},
]
