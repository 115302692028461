import React, {forwardRef} from "react";

const OurServicesThree = forwardRef((props, ref) => {
    const {title, tagline, children} = props
    return <section className="white-bg" ref={ref}>
        <div className="col-md-6 col-sm-4 bg-flex bg-flex-right">
            <div
                className="bg-flex-holder bg-flex-cover"
                style={{
                    background: `url(https://birojasabian.com/wp-content/uploads/2022/10/Group-134-1024x487.png) no-repeat center`,
                    backgroundSize: "contain",
                    backgroundColor: "#6597C9"
                }}
            ></div>
        </div>

        <div className="container">
            <div className="col-md-5 col-sm-7 ">
                <h2 className="font-700">{title}</h2>
                <h4 className="mt-10 line-height-26 default-color">{tagline}</h4>
                {children}
            </div>
        </div>
    </section>
});

export default OurServicesThree;
