import React from "react";
import Sweetalert from "sweetalert2";
import Icofont from "react-icofont";

const PriceItemApp = ({
                          title,
                          currency,
                          price,
                          features,
                          textButton,
                          index,
                          classAppend,
                          active,
                          alertText,
                          icon
                      }) => (
    <div
        className={"col-md-12 col-sm-12 " + (classAppend ? classAppend : "")}
        data-aos-delay={`${index + 1}00`}
        data-aos={"fade-up"}
        style={{
            height: "100%"
        }}
    >
        <div className="pricing-box">
            <Icofont icon={icon} style={{
                fontSize: "60px",
                color: "#324295"
            }} className="mb-3"/>
            <h3 className="font-700 blue-color">{title}</h3>
            {/*<h2 className="blue-color">*/}
            {/*<sup>{currency}</sup>*/}
            {/*<span>{price}</span>*/}
            {/*</h2>*/}
            <ul>
                {features.map((feature) => (
                    <li key={feature.id}>{feature.title}</li>
                ))}
            </ul>
            <div className="pricing-box-bottom">
                <a href="https://client.pajakkan.com" target="_blank"
                   className={`btn ${active ? "btn-color" : "btn-outline-secondary"}  btn-circle`}
                   onClick={(e) => {
                       if (!active) {
                           e.preventDefault()
                           Sweetalert.fire({
                               title: 'Mohon maaf',
                               text: alertText,
                               icon: 'info',
                               confirmButtonText: 'OK'
                           })
                       }
                   }}>
                    {textButton}
                </a>
            </div>
        </div>
    </div>
);

export default PriceItemApp;
