import React from "react";
import dataAccordion from "../../data/Accordion/accordion-faqs-data.json";
import Loader from "../../components/Loader/Loader";
import AccordionsComponent from "../../components/Accordions/AccordionsComponent";
import FooterThree from "../../components/Footer/FooterThree";
import NavHeader from "../../components/Header/NavHeader";
import PageTitleFAQ from "../../components/PageTitle/PageTitleFAQ";

const PageFAQs = () => (
    <Loader>
        <NavHeader/>
        <PageTitleFAQ title="FAQs"
                      tagline="Beberapa pertanyaan yang sering diajukan terkait Biro Jasa Pajak Kendaraan"/>
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-sm-8">
                        <AccordionsComponent type="color" data={dataAccordion}/>
                    </div>
                    <div className="col-sm-4">
                        <form className="search-form" method="get">
                            <input
                                type="text"
                                name="name"
                                className="form-control search-field"
                                id="search"
                                placeholder="Type what it's your mind..."
                            />
                            <button
                                type="submit"
                                className="icofont icofont-search-1 search-submit"
                            ></button>
                        </form>
                        <div className="banner-box help-bg mt-30">
                            <div className="gradient-bg"></div>
                            <div className="relative white-color text-center">
                                <h4 className="text-uppercase">KONSULTASIKAN SECARA GRATIS</h4>
                                <p>
                                    Butuh konsultasi, informasi lebih lanjut, atau ingin memulai kerjasama dengan kami,
                                    jangan ragu untuk menghubungi kami melalui kontak berikut
                                </p>
                                <a href="!#" className="btn btn-outline-white btn-square mt-10">
                                    Hubungi Kami Sekarang
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <FooterThree/>
    </Loader>
);

export default PageFAQs;
