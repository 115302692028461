import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import ScrollToTop from "./helpers/ScrollToTop";
import {Routes} from "./route";

import 'sweetalert2/dist/sweetalert2.css'

function App() {
    return <Router basename={"/"}>
        <ScrollToTop>
            <Switch>
                {
                    Routes.map((v, i) => {
                        return <Route exact={v.exact} path={v.path}
                                      component={v.component}/>
                    })
                }
            </Switch>
        </ScrollToTop>
    </Router>
}

export default App