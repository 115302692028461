import React, {useRef} from "react";
import Loader from "../../components/Loader/Loader";
import ClientsBrand from "../../components/ClientsBrand/ClientsBrand";
import PageTitleAbout from "../../components/PageTitle/PageTitleAbout";
import OurTeamTwo from "../../components/Team/OurTeamTwo";
import FooterThree from "../../components/Footer/FooterThree";
import NavHeader from "../../components/Header/NavHeader";
import AboutArchitecture from "../../components/AboutUs/AboutArchitecture";
import FunFactsArchitecture from "../../components/FunFacts/FunFactsArchitecture";

const PageAboutUs = () => {
    const about = useRef()
    return <Loader>
        <NavHeader/>
        <PageTitleAbout
            title="About Us"
            tagline="BIRO JASA PAJAK KENDARAAN DENGAN BERBAGAI LAYANAN YANG MUDAH, CEPAT, DAN AMAN"
        />
        <AboutArchitecture ref={about}/>
        <FunFactsArchitecture title="PAJAKKAN.COM" tagline="MUDAH, CEPAT, DAN AMAN">
            Pajakkan.com - Solusi Terpercaya untuk Kebutuhan Kendaraan Anda!.
            Solusi yang komprehensif untuk membantu Anda mengatasi semua hambatan administratif dan hukum terkait
            surat menyurat kendaraan. Berbagai layanan kami tawarkan untuk membantu Anda, mulai dari Pajak Tahunan,
            Pajak 5 Tahunan, Balik Nama Satu Wilayah, Balik Nama Antar Wilayah, dsb. Jangan biarkan tugas-tugas
            administratif kendaraan menghalangi Anda. Hubungi kami untuk diskusikan kebutuhan surat menyurat
            kendaraan secara gratis!
        </FunFactsArchitecture>
        <OurTeamTwo title="MEET OUR FOUNDER"
                    tagline="Pajakkan.com juga dibantu oleh tim profesional dari setiap divisi layanan yang kami tawarkan"/>
        <ClientsBrand>
            <h3 className="w-100 text-center mb-2 text-secondary">Klien Kami</h3>
            <h5 className="w-100 text-center mb-5 text-secondary">KAMI TELAH BEKERJA SAMA DENGAN BEBERAPA PERUSAHAAN
                PEMBIAYAAN UNTUK MENGURUS SURAT-SURAT BERBAGAI KENDARAAN MEREKA. BERIKUT ADALAH PARTNER KAMI:</h5>
        </ClientsBrand>
        <FooterThree/>
    </Loader>

}


export default PageAboutUs;
