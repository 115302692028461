import React, {forwardRef} from "react";
import dataPrices from "../../../data/PriceTables/price-appstyle-data.json";
import PriceItemApp from "./PriceItemApp";
import HeadingSection from "../../HeadingSection/HeadingSection";

const PricingAppStyle = forwardRef((props, ref) => (
    <section className="bg-dark" id="pricing" ref={ref}>
        {/*<div className="container">*/}
        <div className="row" style={{
            paddingRight: "3vw",
            paddingLeft: "3vw",
        }}>
            <div className="row mb-5">
                <HeadingSection
                    title="Apa yang anda butuhkan?"
                    tagline="Biro Jasa terbaik untuk urus kebutuhan surat menyurat kendaraan Anda dengan mudah, cepat, dan aman"
                    classAppend="white-color"
                />
            </div>
            {dataPrices.map((item, i) => (
                <div className="col-md-3">
                    <PriceItemApp
                        key={item.id}
                        title={item.title}
                        currency={item.currency}
                        price={item.price}
                        features={item.features}
                        textButton={item.textButton}
                        active={item.active}
                        alertText={item.alertText}
                        index={i}
                        icon={item.icon}
                        classAppend="mb-20"
                    />
                </div>

            ))}
        </div>
        {/*</div>*/}
    </section>
));

export default PricingAppStyle;
