import React, {useEffect, useRef, useState} from "react";
import FooterCopyright from "./FooterCopyright";

const FooterThree = () => {
    const [height, setHeight] = useState(0)
    const ref = useRef(null)

    useEffect(() => {
        setHeight(ref.current.clientHeight)
    }, [])

    return <>
        <footer className="footer" id="footer-fixed" ref={ref}>
            <div className="footer-main">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-4">
                            <div className="widget widget-links">
                                <h5 className="widget-title">Pajakkan.com</h5>
                                <p>
                                    Jl. Serayu Timur I no.2, Kelurahan Pandean, Kecamatan Taman,
                                </p>
                                <p>
                                    Kota Madiun, Jawa Timur 63133
                                </p>

                                <h5 className="widget-title">Jam Layanan</h5>
                                <p className="m-0">Senin - Jumat : 08.00 - 15.00 (WIB)</p>
                                <p className="m-0">Sabtu : 08.00 - 12.00 (WIB)</p>
                                <p>Minggu / Tanggal Merah : Libur</p>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-4">
                            <div className="widget widget-links">
                                <h5 className="widget-title">HUBUNGI KAMI</h5>
                                <p>
                                    Jika ada pertanyaan mengenai Order Kamu, silakan menghubungi kami di
                                </p>
                                <p><strong className="white-color">Official Whatsapp: </strong> 0821 3355 1936</p>
                                <p><strong className="white-color">Email: </strong> cs@birojasabian.com</p>
                                <p>Hati-hati terhadap penipuan yang mengatasnamakan Pajakkan.com. Kami hanya menghubungi
                                    customer melalui nomor Official Whatsapp diatas.</p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4">
                            <div className="widget widget-links">
                                <h5 className="widget-title">PENTING</h5>
                                <p>Seluruh pembayaran dengan metode Direct Transfer dilakukan melalui rekening atas nama
                                    <strong className="white-color"> CV BIAN JASA NUSANTARA</strong>. Untuk metode
                                    pembayaran lain, Kami hanya
                                    menerima pembayaran
                                    resmi melalui website <strong className="white-color"> Pajakkan.com</strong></p>

                                <p>Kami tidak bertanggung jawab terhadap pembayaran yang tidak dilakukan ke atas nama
                                    rekening resmi <strong className="white-color"> CV BIAN JASA NUSANTARA</strong> atau
                                    metode lain diluar website
                                    <strong className="white-color"> Pajakkan.com</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterCopyright/>
        </footer>
        <div className="footer-height" style={{height: `${height - 10}px`}}></div>
    </>

}

export default FooterThree;
