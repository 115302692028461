import React from "react";
import caraKerja from "../../data/About/cara-kerja.json";
import FeatureBoxThree from "../../elements/FeatureBox/FeatureBoxThree";
import HeadingSection from "../HeadingSection/HeadingSection";

const WhoWeAreNine = () => (
    <section>
        <div className="container">
            <div className="row">
                <HeadingSection
                    title="Bagaimana Kami Membantu?"
                    tagline="Menyediakan berbagai solusi Pajak Kendaraan dengan Efisiensi sesuai dengan kebutuhan Anda"
                />
            </div>
            <div className="container">
                <div className="row">
                    {caraKerja.map((feature, i) => (
                        <FeatureBoxThree
                            key={feature.id}
                            icon={feature.icon}
                            title={feature.title}
                            text={feature.text}
                            index={i}
                        />
                    ))}
                </div>
            </div>
        </div>

    </section>
);

export default WhoWeAreNine;
