import React from "react";
import Loader from "../../components/Loader/Loader";
import PageTitleService from "../../components/PageTitle/PageTitleService";
import WhoWeAreNine from "../../components/WhoWeAre/WhoWeAreNine";
import NavHeader from "../../components/Header/NavHeader";
import FooterThree from "../../components/Footer/FooterThree";
import TestimonialsOne from "../../components/Testimonials/TestimonialsOne";
import PricingAppStyle from "../../components/PriceTables/AppStyle/PricingAppStyle";

const PageService = () => (
    <Loader>
        <NavHeader/>
        <PageTitleService title="Layanan Kami"
                          tagline="Tim berpengalaman untuk mengatasi berbagai kebutuhan Jasa Pajak Kendaraan Anda"/>
        <WhoWeAreNine/>
        <PricingAppStyle/>

        {/*<PricingAppStyleWithHeader/>*/}
        <TestimonialsOne title="Testimonials" tagline="Happy clients"/>
        <FooterThree/>
    </Loader>
);

export default PageService;
