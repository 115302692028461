import React, {useEffect, useRef, useState} from "react";
import AOS from "aos";
import Loader from "../../components/Loader/Loader";
import FsLightbox from "fslightbox-react";
import PricingAppStyle from "../../components/PriceTables/AppStyle/PricingAppStyle";
import NavHeader from "../../components/Header/NavHeader";
import dataSlider from "../../data/Slider/seo-agency-data.json";
import HeroSliderTwo from "../../components/Hero/HeroSliderTwo";
import OurServicesThree from "../../components/OurServices/OurServicesThree";
import FooterThree from "../../components/Footer/FooterThree";
import ClientsBrand from "../../components/ClientsBrand/ClientsBrand";
import FunFactsArchitecture from "../../components/FunFacts/FunFactsArchitecture";
import TestimonialsOne from "../../components/Testimonials/TestimonialsOne";

const PageHome = () => {
    const home = useRef();
    const about = useRef();
    const service = useRef();
    const contact = useRef();

    const scrollToSection = (e, content) => {
        e.preventDefault();
        switch (content) {
            case "home":
                home.current.scrollIntoView({behavior: "smooth"});
                break;
            case "service":
                service.current.scrollIntoView({behavior: "smooth"});
                break;
            case "contact":
                contact.current.scrollIntoView({behavior: "smooth"});
                break;
            case "about":
                about.current.scrollIntoView({behavior: "smooth"});
                break;
            default:
        }
    };
    const [toggler, setToggler] = useState(false);
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <Loader>
            <FsLightbox
                toggler={toggler}
                sources={["https://www.youtube.com/watch?v=sU3FkzUKHXU"]}
            />
            <NavHeader
                scrollToSection={scrollToSection}
            />
            <HeroSliderTwo data={dataSlider} ref={home}/>
            <OurServicesThree
                ref={about}
                title="Tentang Kami"
                tagline="Pajakkan.com"
            >
                <p className="mt-20">
                    <strong>Pajakkan.com</strong> adalah sebuah platform pembayaran pajak kendaraan bermotor yang
                    dikembangkan
                    oleh Biro
                    Jasa Bian, sebuah perusahaan pelayanan pengurusan surat-surat kendaraan bermotor. Didirikan pada
                    tahun 2009 sebagai usaha perseorangan, kini dibawah bendera <strong>CV BIAN JASA NUSANTARA</strong>,
                    kami telah
                    melayani lebih dari 50.000 surat-surat kendaraan bermotor mulai dari pajak tahunan, pajak 5 tahunan,
                    balik nama, mutasi, cabut berkas, uji KIR, dan lain-lain.
                </p>
                <p>
                    Selain melayani konsumen wajib pajak, kami juga telah bekerja sama dengan berbagai perusahaan
                    pembiayaan seperti leasing, bank, BPR, dan koperasi untuk membantu proses pajak dan balik nama
                    kendaraan milik nasabah yang BPKB nya masih menjadi jaminan kredit.
                </p>
                <p>
                    Komitmen kami adalah memberikan rasa aman dan nyaman dalam hal pengurusan surat-surat kendaraan
                    bermotor Kamu. Moto kami sederhana, <strong>Mudah dan Anti-Ribet.</strong>
                </p>
            </OurServicesThree>
            {/*<TestimonialsApp toggler={toggler} setToggler={setToggler} ref={service}/>*/}
            <PricingAppStyle/>
            <TestimonialsOne title="Testimonials"
                             tagline="Pelanggan menyatakan puas dengan pelayanan dari Pajakkan. Cek testimoni pelanggan kami selengkapnya "/>
            <ClientsBrand>
                <h3 className="w-100 text-center mb-2 text-black">Partner Kami</h3>
                <h5 className="w-100 text-center mb-5 text-secondary">KAMI TELAH BEKERJA SAMA DENGAN BEBERAPA PERUSAHAAN
                    PEMBIAYAAN UNTUK MENGURUS SURAT-SURAT BERBAGAI KENDARAAN MEREKA. BERIKUT ADALAH PARTNER KAMI:</h5>
            </ClientsBrand>
            <FunFactsArchitecture title="PAJAKKAN.COM" tagline="MUDAH, CEPAT, DAN AMAN">
                Pajakkan.com - Solusi Terpercaya untuk Kebutuhan Kendaraan Anda!.
                Solusi yang komprehensif untuk membantu Anda mengatasi semua hambatan administratif dan hukum terkait
                surat menyurat kendaraan. Berbagai layanan kami tawarkan untuk membantu Anda, mulai dari Pajak Tahunan,
                Pajak 5 Tahunan, Balik Nama Satu Wilayah, Balik Nama Antar Wilayah, dsb. Jangan biarkan tugas-tugas
                administratif kendaraan menghalangi Anda. Hubungi kami untuk diskusikan kebutuhan surat menyurat
                kendaraan secara gratis!
            </FunFactsArchitecture>
            <FooterThree/>
        </Loader>
    );
}

export default PageHome
