import React, {useCallback, useEffect, useState} from "react";
import Scrollspy from "react-scrollspy";
import Icofont from "react-icofont";
import MainLogo from "../MainLogo";
import {Link} from "react-router-dom";
import {Routes} from "../../route";

const NavHeader = ({scrollToSection}) => {
    const [collapse, setCollapse] = useState(false);
    const [fixed, setFixed] = useState(false);

    const handleScroll = useCallback(() => {
        if (window.pageYOffset > 34) {
            setFixed(true);
        } else {
            setFixed(false);
        }
    }, []);

    const showMenu = () => {
        setCollapse(!collapse);
        const menu = document.getElementById("navbar-menu");
        collapse ? menu.classList.remove("in") : menu.classList.add("in");
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [handleScroll]);

    return (
        <>
            <nav
                className={
                    "navbar-scrollspy navbar-seven navbar-expand-lg navbar navbar-light navbar-fixed white bootsnav on no-full " +
                    (fixed ? "" : "navbar-transparent")
                }
                data-minus-value-desktop="70"
                data-minus-value-mobile="55"
                data-speed="1000"
            >
                <div className="container">

                    <MainLogo showMenu={showMenu}/>
                    <div
                        className="navbar-collapse collapse"
                        id="navbar-menu"
                        aria-expanded="false"
                    >
                        <Scrollspy
                            items={["home", "about-us", "service", "contact-us"]}
                            currentClassName="active"
                            className="nav navbar-nav navbar-right nav-scrollspy-onepage"
                            data-in="fadeInLeft"
                        >
                            {/*{HomeNav.map((item) => (*/}
                            {/*    <li key={item.id}>*/}
                            {/*        <a*/}
                            {/*            href={item.link}*/}
                            {/*            onClick={(e) => scrollToSection(e, item.link)}*/}
                            {/*        >*/}
                            {/*            {item.title}*/}
                            {/*        </a>*/}
                            {/*    </li>*/}
                            {/*))}*/}

                            {
                                Routes.map((v, i) => {
                                    return v.label ? <li key={`${i}`}>
                                        <Link to={v.path}> {v.label} </Link>
                                    </li> : ""
                                })
                            }

                            <li key="999">
                                <a
                                    href={`https://client.pajakkan.com`}
                                >
                                    Login
                                </a>
                            </li>
                        </Scrollspy>
                        <div className="navbar-toggler bg-transparent">
                            <div className="mt-4">
                                <p className=""><strong>Jam Layanan Customer Service</strong></p>
                                <p className="mb-0 lh-sm">Senin - Jumat : 08.00 - 15.00 WIB</p>
                                <p className="mb-0 lh-sm">Sabtu : 08.00 - 12.00 WIB</p>
                                <p className="mb-5 lh-sm">Minggu / Tanggal Merah : Libur</p>
                            </div>
                        </div>
                    </div>
                    <button
                        type="button"
                        className={"navbar-toggler " + (collapse ? "collapsed" : "")}
                        data-toggle="collapse"
                        data-target="#navbar-menu"
                        aria-expanded="false"
                        onClick={() => showMenu()}
                    >
                        <Icofont icon="navigation-menu"/>
                    </button>
                </div>
            </nav>
        </>
    );
};

export default NavHeader;
