import React from "react";
import Loader from "../../components/Loader/Loader";
import PageTitleContact from "../../components/PageTitle/PageTitleContact";
import ClientsBrand from "../../components/ClientsBrand/ClientsBrand";
import NavHeader from "../../components/Header/NavHeader";
import FooterThree from "../../components/Footer/FooterThree";
import ContactFour from "../../components/ContactUs/ContactFour";

const PageContactUs = () => (
    <Loader>
        <NavHeader/>
        <PageTitleContact
            title="Contact Us"
            tagline="INGIN BERDISKUSI LEBIH LANJUT? TIM KAMI SIAP MELAYANI ANDA"
        />
        <ContactFour/>
        <ClientsBrand/>
        <FooterThree/>
    </Loader>
);

export default PageContactUs;
