import React, {forwardRef} from "react";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import dataTabs from "../../data/TabsIconSection/tabs-default-data.json";
import img from "../../assets/images/architecture-img.jpg";

const AboutArchitecture = forwardRef((props, ref) => (
    <section id="about" className="pb-80" ref={ref}>
        <div className="container">
            <div className="row">
                <div className="col-md-5 mb-20">
                    <img className="img-fluid" src={"https://i.imgur.com/9hwhmwa.jpg"} alt=""/>
                </div>
                <div className="col-md-7">
                    <h2 className="text-uppercase font-700 mt-0">
                        <span className="font-100">Tentang </span> Pajakkan.com
                    </h2>
                    <p className="mt-20">
                        <strong>Pajakkan.com</strong> adalah sebuah platform pembayaran pajak kendaraan bermotor yang
                        dikembangkan oleh
                        Biro Jasa Bian, sebuah perusahaan pelayanan pengurusan surat-surat kendaraan bermotor.
                    </p>
                    <p className="mt-20">
                        Didirikan pada tahun 2009 sebagai usaha perseorangan, kini dibawah bendera <strong>CV. BIAN JASA
                        NUSANTARA,</strong> kami telah melayani lebih dari 50.000 surat-surat kendaraan bermotor mulai
                        dari pajak tahunan, pajak 5 tahunan, balik nama, mutasi, cabut berkas, uji KIR, dan lain-lain.
                    </p>
                    <p className="mt-20">
                        Selain melayani konsumen wajib pajak, kami juga telah bekerja sama dengan berbagai perusahaan
                        pembiayaan seperti leasing, bank, BPR, dan koperasi untuk membantu proses pajak dan balik nama
                        kendaraan milik nasabah yang BPKB nya masih menjadi jaminan kredit.
                    </p>
                    <p className="mt-20">
                        Komitmen kami adalah memberikan rasa aman dan nyaman dalam hal pengurusan surat-surat kendaraan
                        bermotor Kamu. Moto kami sederhana, Mudah dan Anti-Ribet.
                    </p>
                    <Tabs className="light-tabs mt-50">
                        <TabList className="nav nav-tabs text-left">
                            {dataTabs
                                .filter((v, i) => i < 5)
                                .map((tab) => (
                                    <Tab key={tab.id} className="nav-item react-tabs__tab">
                                        <span>{tab.title}</span>
                                    </Tab>
                                ))}
                        </TabList>
                        <div className="tab-content text-left">
                            {dataTabs
                                .filter((v, i) => i < 5)
                                .map((tab) => (
                                    <TabPanel key={tab.id} className="tab-pane show in active">
                                        <p
                                            data-aos-delay={100}
                                            data-aos={"fade-in"}
                                        >
                                            {tab.text}
                                        </p>
                                    </TabPanel>
                                ))}
                        </div>
                    </Tabs>
                </div>
            </div>
        </div>
    </section>
));

export default AboutArchitecture;
