import React from "react";
import dataSocial from "../../data/Social/social-footer.json";
import Icofont from "react-icofont";
import {Row} from "react-bootstrap";

const FooterCopyright = () => (
    <div className="footer-copyright">
        <div className="container">
            <div className="row">
                <div className="col-md-6 col-xs-12">
                    <ul className="social-media">
                        {dataSocial.filter((v, i) => i < 5).map((item) => (
                            <li key={item.id}>
                                <a href={item.link} target="_blank">
                                    <Icofont icon={item.icon}/>
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="col-md-6 col-xs-12">
                    <Row className="justify-content-end">
                        <div className="copy-right text-right w-100 text-end">
                            © 2023 Biro Jasa Bian. All rights reserved. Website by {" "}
                            <a className="white-color"
                               href="https://gosocial.co.id/service/website/jasa-pembuatan-website">Jasa
                                Pembuatan Website
                            </a>
                        </div>
                    </Row>
                </div>
            </div>
        </div>
    </div>
);

export default FooterCopyright;
